import { t } from '@lingui/macro'
import { Modal } from '@nbit/arco'
import { GivenType, OpenParameters, RuleType } from '@/features/marketing/sign-in-activity/reward-dialog'
import { GetSignInInfoResp } from '@/typings/api/marketing/sign-in'
import { CommonStatusEnum } from '@/constants/marketing/sign-in-activity'

/** 未达成奖励 */
export const renderNoReward = () => (
  <div className="no-reward">
    <span className="money">+0.00</span>
    <span className="tip">{t`no_reward`}</span>
  </div>
)

const getRewardText = ({ givenType, ruleType, days }: OpenParameters['rewards'][number]) => {
  const givenTypeText = givenType === GivenType.FIXED ? t`fixed_rewards` : t`random_rewards`
  switch (ruleType) {
    case RuleType.FIRST:
      return t({
        id: 'get_rewards_tip_first',
        values: { type: givenTypeText },
      })
    case RuleType.SINGLE:
      return t({
        id: 'get_rewards_tip_day',
        values: { type: givenTypeText },
      })
    case RuleType.CONTINUITY:
      return t({
        id: 'get_rewards_tip_multiple',
        values: { day: days, type: givenTypeText },
      })
    default:
      return ''
  }
}

/** 达成奖励 */
export const renderRewardList = (rewards: OpenParameters['rewards']) => (
  <ul className="reward-list">
    {rewards.map((reward, index) => (
      <li key={index}>
        <span className="money">
          +{reward.quantity} {reward.symbol}，
        </span>
        <span className="tip">{getRewardText(reward)}</span>
      </li>
    ))}
  </ul>
)
export const waitForConfirm = (beforeConfirm?: () => Promise<void>) =>
  new Promise<void>((resolve, reject) => {
    const confirm = async () => {
      try {
        if (beforeConfirm) {
          modalInstance.update({ confirmLoading: true })
          await beforeConfirm()
        }
        resolve()
      } catch (error) {
        reject(error)
      } finally {
        modalInstance.update({ confirmLoading: false })
      }
    }
    const modalInstance = Modal.confirm({
      title: t`features_marketing_sign_in_activity_close_confirm_modal_index_vishxx05cw`,
      content: t`features_marketing_sign_in_activity_close_confirm_modal_index_neh4tlut8u`,
      style: { width: 360 },
      icon: null,
      onConfirm: async () => {
        await confirm()
        modalInstance.close()
      },
      onCancel: () => {
        reject()
        modalInstance.close()
      },
    })
  })

/**
 * 判断活动是否有效
 */
export const isActivityValid = (activity?: GetSignInInfoResp) => {
  const { id, isOpenWeb } = activity || {}
  const isValid = `${id}` !== '0' && isOpenWeb === CommonStatusEnum.yes
  return isValid
}

/**
 * 是否是首页
 */
export const isHomePage = path => path === '/' || path === '/recreation'
